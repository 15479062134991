import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        onSubmit: _ctx.onCloseModalErrorBalance,
        title: "Saldo Anda Dibawah Limit !",
        message: "Lakukan pengisian saldo sekarang juga. Pastikan saldo Anda tersedia sebelum melakukan aktivitas pengiriman.",
        image: "image-modal-warning",
        textSuccess: "OK",
        class: "px-8"
      }, null, 8, ["onSubmit"]))
    : _createCommentVNode("", true)
}