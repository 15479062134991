
import { Vue, prop, Options } from "vue-class-component";

class Props {
  data = prop<any>({
    type: Object,
    default: null
  });
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
  timeout = prop<number>({
    type: Number,
    default: 0
  });
  message = prop<string>({
    type: String,
    default: ""
  });
  isAutoDisplay = prop<boolean>({
    type: Boolean,
    default: true
  });
  isAutoClose = prop<boolean>({
    type: Boolean,
    default: false
  });
  customIcon = prop<string>({
    type: String,
    default: ""
  });
  iconColor = prop<string>({
    type: String,
    default: ""
  });
  title = prop<string>({
    type: String,
    default: "Komoditas Group"
  });
}

@Options({
  emits: ["update:modelValue", "refresh", "close"]
})
export default class Notification extends Vue.with(Props) {
  closeNotification() {
    this.$emit("update:modelValue", false);
    this.$emit("close");
  }

  onRefresh(e: any) {
    this.$emit("refresh", e);
    this.closeNotification();
  }

  // update list
  mounted() {
    this.refreshList();
  }

  timer: any = "";
  updated() {
    this.refreshList();
    if (this.isAutoClose) {
      if (this.modelValue) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.closeNotification();
        }, this.timeout * 1000);
      } else {
        this.closeNotification();
      }
    }
  }
  refreshList() {
    if (this.isAutoDisplay && !this.modelValue && this.timeout) {
      setTimeout(() => {
        this.$emit("update:modelValue", true);
      }, this.timeout * 1000);
    }
  }
}
