/* eslint-disable @typescript-eslint/no-empty-function */
import { BaggingDataDetail } from "@/domain/entities/Bagging";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  dateToDateString,
  ellipsisString,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { AccountController } from "@/app/ui/controllers/AccountController";

let doc: any;
let translate: any = () => {};

export const partnerTypes = (type: string) => {
  switch (type) {
    case "console":
      return "Consolidator";
    case "pos":
      return "POS";
    default:
      return "Sub-Consolidator";
  }
};

export const drawCellConditions = (cell: any) => {
  const head = cell.row?.section === "head";
  const body = cell.row?.section === "body";
  return [
    cell.column?.dataKey === "product" && head,
    cell.row?.cells[3].text[0] === "",
    cell.column?.dataKey === "product" && body,
    cell.column?.dataKey === "originCity" && head,
    cell.row?.cells[8].text[0] === "",
    cell.column?.dataKey === "originCity" && body,
    cell.column?.dataKey === "destCity" && head,
    cell.column?.dataKey === "destCity" && body,
    cell.column?.dataKey === "sttNo" && body,
  ];
};

export const drawCellData = (cell: any) => {
  if (drawCellConditions(cell)[0]) {
    cell.row.cells[3].styles = {
      ...cell.row.cells[3].styles,
      cellPadding: {
        ...cell.row.cells[3].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (
    drawCellConditions(cell)[1] ||
    drawCellConditions(cell)[4] ||
    cell.row.cells[8].text[0] === ""
  ) {
    Object.keys(cell.row.cells).forEach(key => {
      Object.keys(cell.row.cells[key].text).forEach(text => {
        cell.row.cells[key].text[text] =
          cell.row.cells[key].text[parseInt(text) + 1] || "";
      });
    });
  }
  if (drawCellConditions(cell)[2]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[3].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[3].x + 0.7,
          cell.row.cells[3].y +
            cell.row.cells[3].contentHeight /
              (1.6333 +
                2.5 *
                  cell.row.cells[3].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[3].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[8].styles = {
      ...cell.row.cells[8].styles,
      cellPadding: {
        ...cell.row.cells[8].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[5]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[8].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[8].x + 0.7,
          cell.row.cells[8].y +
            cell.row.cells[8].contentHeight /
              (1.6333 +
                2.5 *
                  cell.row.cells[8].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[8].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[6]) {
    cell.row.cells[9].styles = {
      ...cell.row.cells[9].styles,
      cellPadding: {
        ...cell.row.cells[9].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[7]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[9].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[9].x + 0.7,
          cell.row.cells[9].y +
            cell.row.cells[9].contentHeight /
              (1.6333 +
                2.5 *
                  cell.row.cells[9].text.filter((item: any) => item !== "")
                    .length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[9].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }

  if (drawCellConditions(cell)[8]) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const img = require("@/app/ui/assets/images/dg-chips.png");
    if (cell.row.cells[1].text[0].includes(".")) {
      doc.addImage(
        img,
        "PNG",
        cell.cell.x + 27,
        cell.cell.y + 2,
        5.5,
        4,
        "dg",
        "NONE"
      );
    }
  }
};

const manifestPdf = (
  dataHead: BaggingDataDetail,
  data: any,
  logo: string,
  barcode: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);
  // format Date
  const formatDate = (date: Date) => {
    return `${dateToDateString(new Date(date))}`;
  };

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.addImage(barcode, "PNG", 157, 29, 45, 20, "barcode", "FAST", 0);
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("Bagging Manifest", 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 275, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text("No. Bag", 10, 35);
  doc.text(`: ${dataHead.bagVendorNo || dataHead.bagNumber}`, 33, 35);

  doc.text(translate("Tanggal Bagging"), 10, 42);
  doc.text(`: ${formatDate(new Date(dataHead.baggingDate))}`, 33, 42);

  doc.text(translate("Kota Asal"), 10, 49);
  doc.text(`: ${dataHead.originCity}`, 33, 49);

  doc.text(translate("Kota Tujuan"), 10, 56);
  doc.text(`: ${dataHead.destinationCity}`, 33, 56);

  doc.text(translate("Nama Partner"), 10, 63);
  doc.text(`: ${ellipsisString(dataHead.partnerName, 70)}`, 33, 63);

  doc.text(translate("Tipe User"), 10, 70);
  doc.text(`: ${partnerTypes(dataHead.partnerType)}`, 33, 70);

  // right column

  doc.text(translate("Dibuat Oleh"), 75, 35);
  doc.text(`: ${dataHead.baggingCreatedBy}`, 115, 35);

  doc.text(translate("Total STT"), 75, 42);
  doc.text(`: ${dataHead.totalStt}`, 115, 42);

  doc.text(translate("Total Koli"), 75, 49);
  doc.text(`: ${dataHead.totalPiece}`, 115, 49);

  doc.text(translate("Total Berat Kotor Bag"), 75, 56);
  doc.text(`: ${formatValueNumber(dataHead.totalBagGrossWeight)} Kg`, 115, 56);

  doc.text(translate("Total Berat Dimensi Bag"), 75, 63);
  doc.text(`: ${formatValueNumber(dataHead.totalBagVolumeWeight)} Kg`, 115, 63);

  doc.text(translate("Total Berat Bag Dikenakan Biaya"), 75, 70);
  doc.text(
    `: ${formatValueNumber(dataHead.totalBagChargeableWeight)} Kg`,
    115,
    70
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTable = () => [
    { header: "No", dataKey: "no" }, 
    { header: `No. STT`, dataKey: "sttNo" },
    { header: translate(`No. Referensi`), dataKey: "sttNoRefExternal" },
    { header: translate("Produk"), dataKey: "product" },
    { header: translate("Komoditas"), dataKey: "commodity" },
    { header: translate("Total Koli"), dataKey: "koli" },
    { header: translate("Berat Kotor"), dataKey: "grossWeight" },
    { header: translate("Berat Dimensi"), dataKey: "volumeWeight" },
    { header: "Origin", dataKey: "originCity" },
    { header: translate("Dest."), dataKey: "destCity" }
  ];
  const columnStyles = {
    0: { cellWidth: 10 },
    1: { cellWidth: 33 },
    2: { cellWidth: 22 },
    3: { cellWidth: 20 },
    4: { cellWidth: 18 },
    5: { cellWidth: 18 },
    6: { cellWidth: 20 },
    7: { cellWidth: 21 },
    8: { cellWidth: 16 },
    9: { cellWidth: 16 }
  };
  !isMigrateFromElexys() && columnsTable().splice(1, 1);
  doc.autoTable({
    body: data,
    columns: columnsTable(),
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 77 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text(translate("Staff Admin"), 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text(translate("Kurir"), 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text(translate("Operations Supervisor"), 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  doc.setProperties({
    title: "Bagging Manifest"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default manifestPdf;
