
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { convertDecimalWithComma } from "@/app/infrastructures/misc/Utils";
import { BaggingDataDetail, SttResult } from "@/domain/entities/Bagging";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";

class Props {
  detailData = prop<BaggingDataDetail>({
    type: Object,
    required: true
  });
}
@Options({
  components: {
    Title
  }
})
export default class DetailBaggingSection extends Vue.with(Props) {
  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  get columns() {
    const column = [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${item.sttNoRefExternal ||
            "-"}</div>`;
        }
      },
      {
        name: this.$t("bagging.tableTitle.weightGross"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-48 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${item.sttCommodityName}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300">${item.sttTotalPiece}</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttOriginCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttDestinationCityId}
                  </div>
                </div>`;
        }
      }
    ];

    if (!this.detailData.isPaid) {
      column.splice(2, 0, {
        name: this.$t("Pembayaran"),
        styleHead: "w-48 text-left text-black-lp-300 whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: SttResult) => {
          return `<div class='text-left ${
            item?.paymentStatus === "unpaid"
              ? "bg-yellow-lp-500"
              : "bg-green-lp-700"
          } text-white font-medium font-montserrat rounded-md px-3 py-1 whitespace-no-wrap text-12px xxl:text-14px'>${
            item?.paymentStatus === "unpaid"
              ? this.$t("Kurang Bayar")
              : this.$t("Sudah Lunas")
          }</div>`;
        }
      });
    }
    return column;
  }
}
