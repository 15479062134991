import { PrinterController } from "@/app/ui/controllers/PrinterController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

const autoPrint = async (stt: string) => {
    const print = await PrinterController.autoPrint({
        id: stt
    });
    return print.data.status;
}

const showLoading = () => {
    MainAppController.showMessageModal(
        new ModalMessageEntities({
            title: "Menunggu Proses Print",
            image: "hourglass",
            imageWidth: 90,
            imageHeight: 90,
            message: "Kami sedang menyiapkan data Anda untuk dicetak",
        })
    );
} 

const uploadFile = async (file: any, stt: string, isBookInstan: boolean) => {
    if (!isBookInstan) showLoading()
    const filePdf = new File([file], `${stt}.pdf`, {
        type: "application/pdf",
        lastModified: Number(new Date())
    });

    return PrinterController.uploadFile({
        file: filePdf
    }).then(() => {
        return autoPrint(stt);
    }).catch(() => false).finally(() => {
        MainAppController.closeMessageModal();
    });
}

export {
    uploadFile
}

