import ApiPrinterService from "@/app/infrastructures/services/ApiPrinterService";
import { PrinterRepositoryInterface } from "@/data/persistences/repositories/contracts/PrinterRepositoryInterface";
import { PrinterEndpoint } from "@/app/infrastructures/endpoints/printer/PrinterEndpoint";
import { UploadApiRequestInterface } from "@/data/payload/contracts/PrinterRequest";

export class PrinterApiRepository implements PrinterRepositoryInterface {
  private service: ApiPrinterService;
  private endpoints: PrinterEndpoint;

  constructor(
    service: ApiPrinterService,
    endpoints: PrinterEndpoint
  ) {
    this.service = service;
    this.endpoints = endpoints;
  }


  public async uploadFile(
    file: UploadApiRequestInterface
  ): Promise<any> {
    const response = await this.service.invokePostWithFormData(
        "post",
        this.endpoints.uploadFile(),
        {},
        file
      );
      return response;
  }

  public async autoPrint(
    id: string
  ): Promise<any> {
    const response = await this.service.invoke(
        "get",
        this.endpoints.autoPrint(id),
        {}
    );
    return response;
  }
}
