import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4 overflow-y-auto" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "pr-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      label: _ctx.$t('Detail Bagging')
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total STT'),
        class: "w-6/12",
        value: _ctx.detailData.totalStt
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total Koli'),
        class: "w-6/12",
        value: _ctx.detailData.totalPiece
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: 'Total ' + _ctx.$t('bagging.tableTitle.weightGross'),
        value: `${Math.ceil(_ctx.detailData.totalGrossWeight)} Kg`,
        class: "w-6/12"
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total Berat Dimensi'),
        value: `${Math.ceil(_ctx.detailData.totalVolumeWeight)} Kg`,
        class: "w-6/12"
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total Bag Gross Weight'),
        value: `${_ctx.formatValueNumber(_ctx.detailData.totalBagGrossWeight)} Kg`,
        class: "w-6/12"
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total Bag Volume Weight'),
        value: `${_ctx.formatValueNumber(_ctx.detailData.totalBagVolumeWeight)} Kg`,
        class: "w-6/12"
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        label: _ctx.$t('Total Bag Chargeable Weight'),
        value: `${_ctx.formatValueNumber(_ctx.detailData.totalBagChargeableWeight)} Kg`,
        class: "w-6/12"
      }, null, 8, ["label", "value"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_TableV2, {
        data: _ctx.detailData.sttResult,
        columns: _ctx.columns,
        borderTop: false,
        hidePagination: true,
        isRightFreeze: true,
        isPinnedScrollBottom: false,
        totalColumnFreeze: 0
      }, null, 8, ["data", "columns"])
    ])
  ]))
}