import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      label: _ctx.$t('Detail Pengiriman')
    }, null, 8, ["label"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: _ctx.$t('Nama Partner'),
        value: _ctx.detailData.partnerName
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: _ctx.$t('Tipe User'),
        value: _ctx.detailData.partnerType
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: _ctx.$t('Kota Asal'),
        value: _ctx.detailData.originCity
      }, null, 8, ["label", "value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: _ctx.$t('Kota Tujuan'),
        value: _ctx.detailData.destinationCity
      }, null, 8, ["label", "value"])
    ])
  ]))
}