
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import OverlayPanel from "primevue/overlaypanel";

@Options({
  components: {
    OverlayPanel
  },
  props: {
    menus: {
      default: [],
      type: Array
    },
    action: {
      default: Function as PropType<() => number>,
      type: Function
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isBottom: {
      default: false,
      type: Boolean
    },
    isPrintAble: {
      default: false,
      type: Boolean
    }
  },

  directives: {
    "click-outside": {
      beforeMount: (el: any, binding: any) => {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: (el: any) => {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Book extends Vue {
  isShow = false;
  showDownloadChoice() {
    this.isShow = !this.isShow;
  }
  toggleTooltip(event: any) {
    this.showDownloadChoice();
    const refs: any = this.$refs;
    refs["opTooltip"].toggle(event);
  }

  hideToggle(event: any) {
    const refs: any = this.$refs;
    refs["opTooltip"].hide(event);
  }

  onCloseAnywhere(value: boolean) {
    this.isShow = value;
  }
}
