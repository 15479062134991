import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/app/ui/assets/svg/chevron-up-white.svg'
import _imports_1 from '@/app/ui/assets/svg/chevron-down-white.svg'


const _hoisted_1 = {
  key: 0,
  class: "cursor-pointer",
  src: _imports_0,
  alt: "up"
}
const _hoisted_2 = {
  key: 1,
  class: "cursor-pointer",
  src: _imports_1,
  alt: "down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode("button", {
      disabled: _ctx.isDisabled,
      class: ["flex flex-row items-center text-white px-6 py-3 bg-red-lp-100 text-12px xxl:text-14px font-bold rounded-md focus:outline-none ml-8", { 'bg-gray-lp-500 cursor-not-allowed': _ctx.isDisabled }],
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltip($event)))
    }, [
      _createTextVNode(_toDisplayString(_ctx.isPrintAble ? "Print" : "Booking") + " ", 1),
      (_ctx.isShow)
        ? (_openBlock(), _createBlock("img", _hoisted_1))
        : (_openBlock(), _createBlock("img", _hoisted_2))
    ], 10, ["disabled"]), [
      [_directive_click_outside, () => _ctx.onCloseAnywhere(false)]
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "opTooltip",
      appendTo: "body",
      class: "w-auto"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
          return (_openBlock(), _createBlock("div", {
            key: index,
            class: 
            `${
              index !== _ctx.menus.length - 1 ? 'border-b border-gray-lp-400' : ''
            } cursor-pointer py-1`
          
          }, [
            _createVNode("div", {
              class: "text-12px xxl:text-14px text-gray-lp-300 p-2 flex flex-row items-center",
              onClick: 
              e => {
                _ctx.action(index);
                _ctx.hideToggle(_ctx.$event);
              }
            
            }, [
              _createVNode("img", {
                class: "pr-2 h-4 xxl:h-5",
                src: require(`@/app/ui/assets/svg/${menu.icon}.svg`),
                alt: menu.icon
              }, null, 8, ["src", "alt"]),
              _createTextVNode(" " + _toDisplayString(menu.title), 1)
            ], 8, ["onClick"])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 512)
  ]))
}