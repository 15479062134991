
import { Vue, Options } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";

@Options({
  components: {
    Title
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class DetailDeliverySection extends Vue {}
