import Axios, { AxiosInstance, AxiosResponse, Method } from "axios";
import qs from "qs";
import { injectable } from "tsyringe";
import { ApiServiceInterface } from "@/data/infrastructures/ApiServiceInterface";

@injectable()
export default class ApiPrinterService implements ApiServiceInterface {
  public client: AxiosInstance;

  constructor() {
    this.client = Axios.create({
      baseURL: "http://localhost:9090",
      timeout: 60000
    });

    this.client.interceptors.response.use(
        resp => {
          return resp;
        },
        async error => {
          return Promise.reject(error);
        }
    );
  }

  public async invoke(
    method: Method = "get",
    url = "",
    params: any = {},
    payload: any = null,
    headers: Map<string, string> = new Map()
  ): Promise<AxiosResponse<any>> {
    this.client.defaults.headers["Content-Type"] = "application/json";
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers.common[key] = value;
    });

    return await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload ? payload.toJSON() : null,
      method
    });
  }

  public async invokePostWithFormData<T>(
    method: Method = "post",
    url = "",
    params: any = {},
    payload: any = null
  ): Promise<AxiosResponse<any>> {
    const result = await this.client.request({
      url,
      params,
      paramsSerializer: par => qs.stringify(par, { encode: false }),
      data: payload.toFormData(),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method
    });
    return result;
  }
}
