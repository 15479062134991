/* eslint-disable @typescript-eslint/camelcase */

import {
    Action,
    getModule,
    Module,
    VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { UploadApiRequest } from "@/data/payload/api/PrinterApiRequest";
import { PrinterPresenter } from "../presenters/PrinterPresenter";
import { PrinterComponent } from "@/app/infrastructures/dependencies/modules/PrinterComponent";
PrinterComponent.init();

@Module({ namespaced: true, dynamic: true, store, name: "printer" })
class PrinterStore extends VuexModule {
    @Action
    public uploadFile(params: {
        file: any;
    }) {
        const presenter = container.resolve(PrinterPresenter);
        return presenter.uploadFile(
            new UploadApiRequest(
                params.file
            )
        );
    }
    @Action
    public autoPrint(params: {
        id: string;
    }) {
        const presenter = container.resolve(PrinterPresenter);
        return presenter.autoPrint(params.id);
    }
}

export const PrinterController = getModule(PrinterStore);
