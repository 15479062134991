import { container } from "tsyringe";
import { PrinterPresenter } from "@/app/ui/presenters/PrinterPresenter";
import { PrinterApiRepository } from "@/app/infrastructures/repositories/api/PrinterApiRepository";
import ApiPrinterService from "@/app/infrastructures/services/ApiPrinterService";
import { PrinterEndpoint } from "@/app/infrastructures/endpoints/printer/PrinterEndpoint";

export class PrinterComponent {
  public static init() {
    container.register<PrinterApiRepository>(PrinterApiRepository, {
      useFactory: d => {
        return new PrinterApiRepository(
          d.resolve(ApiPrinterService),
          d.resolve(PrinterEndpoint)
        );
      }
    });
    container.register<PrinterPresenter>(PrinterPresenter, {
      useFactory: d => {
        return new PrinterPresenter(d.resolve(PrinterApiRepository));
      }
    });
  }
}
