import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";

const messageModal = (title: string, image: string, message: string) => {
    MainAppController.showMessageModal(
        new ModalMessageEntities({
            title: title,
            image: image,
            imageWidth: "24",
            imageHeight: "24",
            message: message,
        })
    );
    setTimeout(() => {
        MainAppController.setOpenMessageModal(false);
    }, 3000);
}

export default messageModal;