/* eslint-disable @typescript-eslint/no-empty-function */
import { BaggingData } from "@/domain/entities/Bagging";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode";
import {
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { AccountController } from "@/app/ui/controllers/AccountController";

let translate: any = () => {};

const labelPdf = async (data: BaggingData, bagVendorNo: string, logo: string, barcode: string) => {
  const doc: any = new jsPDF("p", "mm", "a6", true);
  const convertQRCode = async (text: string) => {
    return QRCode.toDataURL(text).then((url: string) => {
      return url;
    });
  };
  const combineQRText = `${bagVendorNo || data.bagCode}#${data.bagOriginCityCode}#${
    data.bagDestinationCityCode
  }#${data.bagTotalChargeableWeight}#${formatValueNumber(data.bagCustomGrossWeight)}#${
    formatValueNumber(data.bagCustomVolumeWeight)
  }#${data.bagCreatedAt.slice(0, 10)}`;

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const qrcodeValue = await convertQRCode(combineQRText);

  doc.addImage(logo, "PNG", 5, 3.5, 25, 5.7, "logo", "FAST", 0);
  doc.addImage(barcode, "PNG", 4, 22, 51, 15, "barcode", "FAST", 0);
  doc.addImage(qrcodeValue, "PNG", 60, 88, 43, 43, "qrcode", "FAST", 0);

  doc.setFillColor("#000000");
  doc.rect(1, 1, 102.9, 0.3, "F");
  doc.rect(1, 1, 0.3, 145.85, "F");
  doc.rect(103.62, 1, 0.3, 145.85, "F");
  doc.rect(1, 146.56, 102.9, 0.3, "F");
  doc.rect(62, 1, 0.3, 11, "F");
  doc.rect(1, 12, 102.9, 0.3, "F");
  doc.rect(1, 51.4, 102.9, 0.3, "F");
  doc.rect(1, 87.8, 102.9, 0.3, "F");
  doc.rect(1, 133.8, 102.9, 0.3, "F");

  doc.setFont("helvetica", "normal");
  doc.setFontSize(9);
  doc.setTextColor("#333333");
  const dateString = dateToDateString(data.bagCreatedAt);
  const substractX =
    dateString.split(" ")[0].length + dateString.split(" ")[1].length;
  doc.text(dateString, -0.8214 * substractX + 77.857, 7.5);

  doc.text(ellipsisString(data.bagPartnerName, 45), 5, 19);

  doc.setFontSize(20);
  doc.setFont("helvetica", "bold");
  doc.text(bagVendorNo || data.bagCode, 5, 45);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(translate("Kota Asal"), 5, 60);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(34);
  doc.text(data.bagOriginCityCode, 5, 75);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(data.bagOriginCityName, 5, 81);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(translate("Kota Tujuan"), 65, 60);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(34);
  doc.text(data.bagDestinationCityCode, 65, 75);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(data.bagDestinationCityName, 65, 81);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(14);
  doc.text(
    `${
      formatValueNumber(data.bagCustomGrossWeight > data.bagCustomVolumeWeight
        ? data.bagCustomGrossWeight
        : data.bagCustomVolumeWeight)
    } Kg`,
    5,
    101
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);
  doc.text(`${formatValueNumber(data.bagCustomGrossWeight)} Kg`, 5, 114);
  doc.text(`${formatValueNumber(data.bagCustomVolumeWeight)} Kg`, 5, 126);
  doc.text(`${data.bagTotalStt}`, 45, 114);
  doc.text(`${data.bagTotalPiece}`, 45, 126);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(8);
  doc.text(translate("Total Bag Chargeable Weight"), 5, 95);
  doc.text("Total Bag Gross Weight", 5, 110);
  doc.text("Total STT", 45, 110);
  doc.text("Total Bag Volume Weight", 5, 122);
  doc.text(translate("Total Koli"), 45, 122);
  doc.setFontSize(6);
  doc.text(
    translate("Terjadi Kendala pada proses pengiriman? Hubungi Customer Service 021-80820072\natau hubungi Origin yang bersangkutan."),
    5,
    140
  );
  doc.setProperties({
    title: "Bagging Manifest"
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

export default labelPdf;
