/* eslint-disable @typescript-eslint/camelcase */
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { jsPDF } from "jspdf";
import { layoutSection } from "../layout";

const generatePDF = async (
  bookingType: string,
  accountType: string,
  data: PrintDataEntitas,
  barcode: string,
  vendorRefCode: string,
  barcodeValue: string,
  vendorRefCodeValue: string,
  logo: string,
  shipmentId: string,
  newTab = true,
  language = "id"
) => {
  const doc: any = new jsPDF("p", "mm", "a4", true);

  const resp: any = data.sttPieces;
  let newArr: any = [];

  function groupByPage(arr: any, size: number) {
    const perPage = Array.from(
      { length: Math.ceil(arr.length / size) },
      (v, i) => arr.slice(i * size, i * size + size)
    );
    newArr = perPage;
  }

  groupByPage(resp, 4);

  for (let i = 0; i < newArr.length; i++) {
    for (let j = 0; j < newArr[i].length; j++) {
      if (j === 0) {
        /* --------------------------
        ---------- SECTION 1 --------
        --------------------------- */
        await layoutSection(
          {
            doc,
            bookingType,
            accountType,
            data,
            pieceData: newArr[i][j],
            barcode,
            vendorRefCode,
            barcodeValue,
            vendorRefCodeValue,
            logo,
            shipmentId,
            startX: 0,
            startY: 0
          },
          language
        );
      } else if (j === 1) {
        /* --------------------------
        ---------- SECTION 2 --------
        --------------------------- */
        await layoutSection(
          {
            doc,
            bookingType,
            accountType,
            data,
            pieceData: newArr[i][j],
            barcode,
            vendorRefCode,
            barcodeValue,
            vendorRefCodeValue,
            logo,
            shipmentId,
            startX: 104,
            startY: 0
          },
          language
        );
      } else if (j === 2) {
        /* --------------------------
       ---------- SECTION 3 --------
       --------------------------- */
        await layoutSection(
          {
            doc,
            bookingType,
            accountType,
            data,
            pieceData: newArr[i][j],
            barcode,
            vendorRefCode,
            barcodeValue,
            vendorRefCodeValue,
            logo,
            shipmentId,
            startX: 0,
            startY: 147
          },
          language
        );
      } else {
        /* --------------------------
        ---------- SECTION 4 --------
        --------------------------- */
        await layoutSection(
          {
            doc,
            bookingType,
            accountType,
            data,
            pieceData: newArr[i][j],
            barcode,
            vendorRefCode,
            barcodeValue,
            vendorRefCodeValue,
            logo,
            shipmentId,
            startX: 104,
            startY: 147
          },
          language
        );
        if (newArr[i + 1] !== undefined) {
          doc.addPage("a4", "p");
        }
      }
    }
  }
  // print
  doc.setProperties({
    title: `Resi-${data.sttNo}.pdf`
  });

  doc.autoPrint();

  if (newTab) {
    window.open(doc.output("bloburl"), "_blank");
  } else {
    window.location.replace(doc.output("bloburl"));
  }
};

export default generatePDF;
