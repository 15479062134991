/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { UploadApiRequestInterface } from "../contracts/PrinterRequest";

export class UploadApiRequest implements UploadApiRequestInterface {
  private file: boolean;

  constructor(
    file: any
  ) {
    this.file = file
  }

  public toFormData() {
    const data = {
        id: this.file
    };
    return toFormDataUtils(data);
  }
}
