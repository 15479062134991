
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import DetailDeliverySection from "./detail-delivery-section.vue";
import DetailBagging from "./detail-bagging-section.vue";
import OverlayPanel from "primevue/overlaypanel";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { BaggingController } from "@/app/ui/controllers/BaggingController";
import PrintManifest from "../modules/print.vue";
import { BaggingData } from "@/domain/entities/Bagging";

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    Title,
    DetailDeliverySection,
    DetailBagging,
    OverlayPanel,
    Book,
    PrintManifest
  },
  computed: {
    getTitle() {
      return `${this.detailData.bagVendorNo || this.detailData.bagNumber}`;
    }
  }
})
export default class Detail extends Vue {
  // Get Data
  created() {
    this.getDetail();
  }

  refs: any = "";
  mounted() {
    this.refs = this.$refs;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // route navigation
  goBack() {
    const path =
      (this.$route.meta as any)["parent-path"] || this.$route.matched[0].path;
    this.$router.push(path);
  }

  getDetail() {
    BaggingController.getDetailBagging(this.id);
  }

  get detailData() {
    return BaggingController.baggingDetail;
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.baggingDate);
  }

  get isLoading() {
    return BaggingController.isLoadingDetail;
  }
  get isError() {
    return BaggingController.isError;
  }
  get errorCause() {
    return BaggingController.errorCause;
  }

  goToEdit() {
    this.$router.push(`${this.$route.path}/edit`);
  }

  // handle print
  printManifest() {
    const data = this.detailData;
    this.refs.printManifest.printManifest(data);
  }
  printPdf() {
    const labelData = new BaggingData({
      bagId: this.id,
      bagCode: this.detailData.bagNumber,
      bagPartnerId: 0,
      bagPartnerCode: "",
      bagPartnerName: this.detailData.partnerName,
      bagOriginCityCode: this.detailData.originCity.slice(0, 3),
      bagOriginCityName: this.detailData.originCity.substring(
        6,
        this.detailData.originCity.length
      ),
      bagOriginTransitCityCode: "",
      bagOriginTransitCityName: "",
      bagDestinationCityCode: this.detailData.destinationCity.slice(0, 3),
      bagDestinationCityName: this.detailData.destinationCity.substring(
        6,
        this.detailData.destinationCity.length
      ),
      bagTotalStt: this.detailData.totalStt,
      bagTotalGrossWeight: this.detailData.totalGrossWeight,
      bagTotalVolumeWeight: this.detailData.totalVolumeWeight,
      bagTotalChargeableWeight: this.detailData.totalBagChargeableWeight,
      bagTotalPiece: this.detailData.totalPiece,
      bagCreatedAt: new Date(this.detailData.baggingDate).toISOString(),
      bagCreatedBy: 0,
      bagCreatedName: "",
      bagCustomGrossWeight: this.detailData.totalBagGrossWeight,
      bagCustomVolumeWeight: this.detailData.totalBagVolumeWeight,
      userType: ""
    });
    this.refs.printManifest.printLabel(labelData, this.detailData.bagVendorNo);
  }
}
