
import { Options, Vue } from "vue-class-component";
import { formatDate } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {},
  props: {
    createdBy: {
      default: "",
      type: String
    },
    updatedBy: {
      default: "",
      type: String
    },
    createdAt: {
      default: "",
      type: [String, Date]
    },
    updatedAt: {
      default: "",
      type: [String, Date]
    }
  }
})
export default class CreateUpdate extends Vue {
  props: any = this.$props;

  get convertCreatedAt() {
    return formatDate(this.props.createdAt);
  }

  get convertUpdatedAt() {
    return formatDate(this.props.updatedAt);
  }
}
