import { injectable } from "tsyringe";
import { PrinterApiRepository } from "@/app/infrastructures/repositories/api/PrinterApiRepository";
import { UploadApiRequestInterface } from "@/data/payload/contracts/PrinterRequest";

@injectable()
export class PrinterPresenter {
  private repository: PrinterApiRepository;

  constructor(repository: PrinterApiRepository) {
    this.repository = repository;
  }

  public uploadFile(
    file: UploadApiRequestInterface
  ): Promise<any> {
    return this.repository.uploadFile(file);
  }

  public autoPrint(
    id: string
  ): Promise<any> {
    return this.repository.autoPrint(id);
  }
}
