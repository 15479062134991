
import { Vue, Options } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BaggingController } from "@/app/ui/controllers/BaggingController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { OUTGOING_SHIPMENT_BAGGING } from "@/app/infrastructures/misc/RolePermission";
import Print from "./modules/print.vue";
import debounce from "lodash/debounce";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import {
  BaggingData,
  PayloadUpdateReadyToCargo
} from "@/domain/entities/Bagging";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import Checkbox from "primevue/checkbox";
import { flags } from "@/feature-flags";
import formatValueNumber from "@/app/infrastructures/misc/common-library/FormatValueNumber";
/* eslint-disable @typescript-eslint/camelcase */
@Options({
  components: {
    Print,
    EmptyState,
    Checkbox
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
    ableToCreate() {
      return checkRolePermission(OUTGOING_SHIPMENT_BAGGING.CREATE);
    },
    ableToDetail() {
      return checkRolePermission(OUTGOING_SHIPMENT_BAGGING.DETAIL);
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Bagging extends Vue {
  get isShowRTC() {
    return flags.feature_rtc_bagging_enabled.isEnabled() && !this.isAccountForeign;
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  refs: any = this.$refs;
  mounted() {
    this.refs = this.$refs;
    this.clearSearch();
  }

  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  showModalSTTPaid = false;
  handlerModalSTTPaid(val: boolean) {
    if (this.isAccountForeign) {
      this.$router.push("/bagging/create");
      dataLayer("buat_bagging_click", {}, ["userId", "username", "userType"]);
      dataLayer("page_view", { detail_bagging_form: "" }, []);
    } else {
      this.showModalSTTPaid = val;
    }
  }

  isRTC = false;

  // time-range-picker
  minDate: any = null;
  maxDate: any = null;
  tempPeriodeStart: any = null;
  tempPeriodeEnd: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.tempPeriodeStart = this.periodeStart;
      this.tempPeriodeEnd = this.periodeEnd;
      BaggingController.setPeriodeStart(null);
      BaggingController.setPeriodeEnd(null);
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 13)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 13)
      );
      this.isStartDate = false;
    } else {
      this.periodeEnd && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      BaggingController.setPeriodeStart(this.tempPeriodeStart);
      BaggingController.setPeriodeEnd(this.tempPeriodeEnd);
      this.tempPeriodeStart = null;
      this.tempPeriodeEnd = null;
      this.isStartDate = true;
    }
  }

  fetchBaggingList() {
    BaggingController.fetchBaggingList();
    this.readyToDelete = [];
    this.readyToProcess = [];
  }

  // notification
  get notificationsData() {
    return BaggingController.responseCreate.data;
  }

  // loading
  get isLoading() {
    return BaggingController.isLoading;
  }

  // error cause
  get errorCause() {
    return BaggingController.errorCause;
  }

  // filter date
  get periodeStart() {
    return BaggingController.periodeStart;
  }

  get periodeEnd() {
    return BaggingController.periodeEnd;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    BaggingController.setPeriodeStart(value[0]);
    BaggingController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchBaggingList();
  }

  // filter search
  get searchValue() {
    return BaggingController.filter.search;
  }

  onSearch = debounce((val: string) => {
    BaggingController.setSearch(val);
    this.fetchBaggingList();
  }, 250);

  clearSearch() {
    BaggingController.setSearch("");
    this.fetchBaggingList();
  }

  // goToCreate
  goToCreate() {
    this.$router.push("/bagging/create");
    this.handlerModalSTTPaid(false);
    dataLayer("buat_bagging_click", {}, ["userId", "username", "userType"]);
    dataLayer("page_view", { detail_bagging_form: "" }, []);
  }

  // Table

  get account() {
    return AccountController.accountData;
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: BaggingData, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${item.bagCode}</div>`;
        }
      },
      {
        name: this.$t("bagging.tableTitle.partnerName"),
        styleHead: "w-50 text-left",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.bagPartnerName,
            69
          )}</div>`;
        },
        vtooltip: (item: BaggingData) =>
          item.bagPartnerName.length > 69 ? item.bagPartnerName : ""
      },
      {
        name: this.$t("bagging.tableTitle.userType"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${item.userType}</div>`;
        }
      },
      {
        name: this.$t("bagging.tableTitle.destinationType"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${item.bagDestinationCityCode} - ${item.bagDestinationCityName}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${item.bagTotalStt.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: this.$t("bagging.tableTitle.totalPieces"),
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${item.bagTotalPiece.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: this.$t("bagging.tableTitle.baggingDate"),
        styleHead: "w-50 text-left",
        render: (item: BaggingData) => {
          return `<div class="text-black-lp-300">${formatDate(
            item.bagCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500 text-12px">${
              item.bagCreatedName
            }</div>`;
        }
      },
      {
        name: "Total Bag Gross Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(item.bagCustomGrossWeight)} Kg</div>`;
        }
      },
      {
        name: "Total Bag Volume Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(item.bagCustomVolumeWeight)} Kg</div>`;
        }
      },
      {
        name: "Total Bag Chargeable Weight",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: BaggingData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(this.totalBagChargeableWeight(
            item
          ))} Kg</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleButton: (item: BaggingData) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "printer",
                label: "Print PDF",
                clickFunction: async () => {
                  MainAppController.showLoading();
                  const printManifest = await BaggingController.getDetailBagging(
                    item.bagId
                  );
                  if (printManifest) {
                    const data = BaggingController.baggingDetail;
                    this.refs.print.printManifest(data);
                  }
                  MainAppController.closeLoading();
                }
              },
              {
                icon: "invoice",
                label: "Print Label",
                clickFunction: async() =>{ 
                  MainAppController.showLoading();
                  const printLabel = await BaggingController.getDetailBagging(
                    item.bagId
                  );
                  if (printLabel) {
                    const bagVendorNo = BaggingController.baggingDetail.bagVendorNo;
                    this.refs.print.printLabel(item, bagVendorNo);
                    }
                  MainAppController.closeLoading()
                }
              },
              {
                icon: "download-gray",
                label: "Download Excel",
                clickFunction: () =>
                  BaggingController.downloadBagging(item.bagId)
              }
            ]
          };
        }
      }
    ];
  }

  totalBagChargeableWeight(item: BaggingData) {
    return item.bagCustomVolumeWeight > item.bagCustomGrossWeight
      ? item.bagCustomVolumeWeight
      : item.bagCustomGrossWeight;
  }

  chevronUp: boolean[] = [];

  get pagination() {
    return BaggingController.baggingList.pagination;
  }

  goToDetail(item: BaggingData) {
    this.$router.push(`/bagging/${item.bagId}?params=${item.bagCode}`);
  }

  get baggingList() {
    const data = BaggingController.baggingList.data;
    this.chevronUp = data.map(() => false);
    return data;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  readyToProcess: BaggingData[] = [];
  readyToDelete: BaggingData[] = [];

  get bundleData() {
    return this.readyToProcess
      .concat(this.readyToDelete)
      .map((key: BaggingData) => {
        return {
          is_rtc: key.isAbleRTC,
          bag_id: key.bagId,
          bag_no: key.bagCode
        };
      });
  }

  get errorMessageBaggingRTC() {
    return BaggingController.errorMessageBaggingRTC;
  }

  async updateBaggingToRTC() {
    const payload = new PayloadUpdateReadyToCargo({ bagList: this.bundleData });
    const updateBaggingToRtc = await BaggingController.updateToRTCBagging(payload);
    if (updateBaggingToRtc) {
      this.showSuccessReadyToCargo();
    } else {
      if (
        this.errorMessageBaggingRTC?.message.includes(
          "Salah satu bag memiliki salah satu STT yang unpaid"
        )
      ) {
        this.showPopupSTTUnpaid();
      } else {
        this.showCancelReadyToCargo();
      }
    }
  }

  get titlePopupWording() {
    return `${
      this.readyToProcess.length ? "Lanjutkan Proses ke" : "Hapus Bagging dari"
    }`;
  }

  get subtitlePopupWording() {
    return `${
      this.readyToProcess.length
        ? "No. Bagging akan otomatis terupdate dan masuk ke daftar"
        : "No. Bagging akan otomatis terhapus dari daftar"
    }`;
  }

  get titlePopupErrorWording() {
    return `${
      this.readyToProcess.length
        ? "No. Bagging Gagal Diupdate"
        : "No. Bagging Gagal Dihapus"
    }`;
  }

  get subtitlePopupErrorWording() {
    return `${
      this.readyToProcess.length
        ? "No. Bagging telah gagal ditambahkan ke daftar"
        : "No. Bagging telah gagal terhapus dari daftar"
    }`;
  }

  showPopupUpdateToReadyToCargo() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title:
          this.readyToProcess.length && this.readyToDelete.length
            ? "Lanjutkan Proses & Hapus ke Siap Dikargo?"
            : `${this.titlePopupWording} Siap Dikargo?`,
        image: "are-you-sure",
        message:
          this.readyToProcess.length && this.readyToDelete.length
            ? "Pastikan No. Bagging yang dipilih telah sesuai"
            : `${this.subtitlePopupWording} Siap Dikargo`,
        textCancel: "Kembali",
        textSuccess: this.readyToProcess.length ? "Lanjutkan" : "Hapus",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onShowLoadingSave();
        }
      })
    );
  }

  onDelaySave = debounce(()=>{
    this.updateBaggingToRTC()
  }, 250)

  onShowLoadingSave() {
    MainAppController.showLoading();
    this.onDelaySave();
  }

  resetReadyToCargo() {
    MainAppController.closeMessageModal();
    this.fetchBaggingList();
    this.readyToDelete = [];
    this.readyToProcess = [];
  }

  showSuccessReadyToCargo() {
    const onDeleteWording = this.readyToDelete.length ? 'dan Dihapus' : '';
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: this.readyToDelete.length && !this.readyToProcess.length ? "image-modal-success" : "rtc-background-process",
        title: this.readyToProcess.length
          ? `Bagging Akan Diproses ${onDeleteWording} RTC`
          : "No. Bagging Berhasil Dihapus",
        message:
          this.readyToDelete.length && !this.readyToProcess.length
            ? "No. Bagging telah terhapus dari daftar Siap Dikargo"
            : `Silahkan lihat bagging Anda saat proses booking kargo`,
        textSuccess: "OK",
        onSubmit: () => this.resetReadyToCargo()
      })
    );
  }

  showCancelReadyToCargo() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "image-modal-failed",
        title:
          this.readyToProcess.length && this.readyToDelete.length
            ? "Tambah dan Hapus No. Bagging Gagal"
            : `${this.titlePopupErrorWording}`,
        message:
          this.readyToProcess.length && this.readyToDelete.length
            ? "Tambah dan hapus No. Bagging gagal dilakukan."
            : `${this.subtitlePopupErrorWording} Siap DiKargo`,
        textSuccess: "OK",
        onSubmit: () => this.resetReadyToCargo()
      })
    );
  }

  showPopupSTTUnpaid() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "new-badge-failed",
        title: "Beberapa No. Bagging Gagal Diproses ",
        message: "Terjadi kelebihan berat dan kurang bayar. Tunggu sampai customer melunasi tagihan",
        textSuccess: "OK",
        onSubmit: () => this.resetReadyToCargo()
      })
    );
  }
}
