/* eslint-disable @typescript-eslint/camelcase */
import { jsPDF } from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { layoutSection } from "../layout";
import { uploadFile } from "../auto-print";
import messageModal from "@/app/infrastructures/misc/common-library/MessageModal";

const openTab = (doc: any, newTab: boolean, stt: string) => {
  doc.setProperties({
    title: `resi-thermal - ${stt}.pdf`
  });

  doc.autoPrint();
  if (newTab) {
    window.open(doc.output("bloburl"), "_blank");
  } else {
    window.location.replace(doc.output("bloburl"));
  }
};

export const regularBook = async (
  autoPrint: boolean,
  newTab: boolean,
  doc: jsPDF,
  data: PrintDataEntitas
) => {
  if (autoPrint) {
    const print = await uploadFile(doc.output("blob"), data.sttNo, false);
    if (!print) {
      openTab(doc, newTab, data.sttNo);
      messageModal(
        "Label Gagal Diprint",
        "circle-error",
        "Pastikan semua perangkat Anda telah terhubung. <br/> Cek & coba lagi"
      );
    } else {
      messageModal(
        "Label Berhasil Diprint",
        "badge-success",
        "Anda berhasil mencetak data yang diinginkan"
      );
    }
  } else {
    openTab(doc, newTab, data.sttNo);
  }
};

export const instanBook = async (doc: jsPDF, data: PrintDataEntitas) => {
  const print = await uploadFile(doc.output("blob"), data.sttNo, true);
  return print;
};

const generatePDF = async (
  bookingType: string,
  accountType: string,
  data: PrintDataEntitas,
  barcode: string,
  vendorRefCode: string,
  barcodeValue: string,
  vendorRefCodeValue: string,
  logo: string,
  shipmentId: string,
  newTab = true,
  language = "id",
  autoPrint = false,
  isBookInstan = false
) => {
  const doc: any = new jsPDF("p", "mm", "a6", true);

  let index = 0;
  for (const item of data.sttPieces) {
    if (index > 0) {
      doc.addPage("a6", "p");
    }
    await layoutSection(
      {
        doc,
        bookingType,
        accountType,
        data,
        pieceData: item,
        barcode,
        vendorRefCode,
        barcodeValue,
        vendorRefCodeValue,
        logo,
        shipmentId,
        startX: 0,
        startY: 0
      },
      language
    );

    index++;
  }

  doc.setProperties({
    title: `resi-thermal - ${data.stt}.pdf`
  });

  if (isBookInstan) {
    return instanBook(doc, data);
  } else {
    return regularBook(autoPrint, newTab, doc, data);
  }
};

export default generatePDF;
