import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-3" }
const _hoisted_2 = { class: "text-gray-lp-800 text-12px mb-2" }
const _hoisted_3 = { class: "mt-1 mb-0.5 font-semibold text-black-lp-300 text-12px xxl:text-14px font-normal" }
const _hoisted_4 = { class: "text-gray-lp-300 text-12px xxl:text-14px" }
const _hoisted_5 = { class: "py-3" }
const _hoisted_6 = { class: "text-gray-lp-800 text-12px mb-2" }
const _hoisted_7 = { class: "mt-1 mb-0.5 font-semibold text-black-lp-300 text-12px xxl:text-14px font-normal" }
const _hoisted_8 = { class: "text-gray-lp-300 text-12px xxl:text-14px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('bookingShipment.detail.modifiedDate')), 1),
      _createVNode("div", _hoisted_3, _toDisplayString(_ctx.convertUpdatedAt), 1),
      _createVNode("div", _hoisted_4, _toDisplayString(_ctx.updatedBy), 1)
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('bookingShipment.detail.createdDate')), 1),
      _createVNode("div", _hoisted_7, _toDisplayString(_ctx.convertCreatedAt), 1),
      _createVNode("div", _hoisted_8, _toDisplayString(_ctx.createdBy), 1)
    ])
  ]))
}