
import { Vue, Options } from "vue-class-component";

@Options({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  components: {}
})
export default class ModalErrorBalance extends Vue {
  onCloseModalErrorBalance() {
    this.$emit("update:modelValue", false);
  }
}
