import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import removeDecimalWithoutRounding from "@/app/infrastructures/misc/common-library/RemoveDecimalWithoutRounding";
import startCase from "lodash/startCase";
import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { formatPrice } from "@/app/infrastructures/misc/Utils";

const generateCommercialInvoice = (
  headerData: PrintDataEntitas,
  logo: string,
  language = "en"
) => {
  const doc: any = new jsPDF("p", "mm", "a4", true);

  const i18nInstance = createI18n({
    legacy: false,
    locale: language,
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my,
    },
  });
  const translate = i18nInstance.global.t;

  doc.setFont("helvetica", "bold");
  doc.setFontSize(12);
  doc.setTextColor("#4d4d4d");
  doc.text("COMMERCIAL INVOICE", 7, 12);

  doc.addImage(logo, "PNG", 135, 15, 60, 14, "logo", "FAST");

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.date")), 7, 23);
  doc.setFont("helvetica", "normal");
  doc.text(":", 40, 23);
  doc.text(
    dateToDateString(headerData.sttCreatedAt, false, false, language),
    42,
    23
  );

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text("STT", 7, 30);
  doc.setFont("helvetica", "normal");
  doc.text(":", 40, 30);
  doc.text(headerData.sttNo, 42, 30);

  doc.setFillColor("#e0e0e0");
  doc.rect(0, 40, 250, 0.2, "F");

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.shipperName")), 7, 53);
  doc.setFont("helvetica", "normal");
  doc.text(":", 40, 53);
  doc.text(ellipsisString(headerData.sttSenderName, 17).toUpperCase(), 42, 53);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.shipperPhone")), 7, 60);
  doc.setFont("helvetica", "normal");
  doc.text(":", 40, 60);
  doc.text(headerData.sttSenderPhone, 42, 60);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.shipperAddress")), 7, 67);
  doc.setFont("helvetica", "normal");
  doc.text(":", 40, 67);
  doc.text(headerData.sttOriginCityName, 42, 67);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.conssigneeName")), 90, 53);
  doc.setFont("helvetica", "normal");
  doc.text(":", 127, 53);
  doc.text(
    ellipsisString(headerData.sttRecipientName, 25).toUpperCase(),
    129,
    53
  );

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.conssigneePhone")), 90, 60);
  doc.setFont("helvetica", "normal");
  doc.text(":", 127, 60);
  doc.text(headerData.sttRecipientPhone, 129, 60);

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text(startCase(translate("invoice.conssigneeAddress")), 90, 67);
  doc.setFont("helvetica", "normal");
  doc.text(":", 127, 67);
  const address = `${ellipsisString(
    headerData.sttRecipientAddress.toUpperCase(),
    96
  )}.\n${headerData.sttDestinationDistrictName}, ${headerData.sttPostalCode}`;
  doc.text(doc.splitTextToSize(address, 75), 129, 67);

  let lastY = 0;
  let lastHeight = 0;
  const currency =
    headerData.sttBookedByCountry.toLowerCase() === "my"
      ? formatPrice(headerData.sttGoodsEstimatePrice, "", "MYR")
      : `${removeDecimalWithoutRounding(headerData.sttGoodsEstimatePrice)} USD`;
  const data = [
    {
      no: 1,
      itemDescription: ellipsisString(headerData.sttCommodityDescription, 30),
      unitValue: currency,
      qty: headerData.sttPieces.length,
      totalValue: currency,
      weight: headerData.sttChargeableWeight,
    },
  ];
  const dataLength = data.length;

  // main table
  const columnsTable = [
    {
      header: "No.",
      dataKey: "no",
    },
    {
      header: translate("invoice.itemDescription"),
      dataKey: "itemDescription",
    },
    {
      header: `${translate(
        "invoice.unitValue"
      )}\n (MYR/SGD/USD)`,
      dataKey: "unitValue",
    },
    {
      header: translate("invoice.qty"),
      dataKey: "qty",
    },
    {
      header: translate("invoice.totalValue"),
      dataKey: "totalValue",
    },
    {
      header: `${translate("invoice.weight")} (Kg)`,
      dataKey: "weight",
    },
  ];

  const columnStyles = {
    0: { cellWidth: 13 },
    1: { cellWidth: 60 },
    2: { cellWidth: 40 },
    3: { cellWidth: 30 },
    4: { cellWidth: 30 },
    5: { cellWidth: 25 },
  };

  doc.autoTable({
    body: data,
    columns: columnsTable,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 90 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 8,
      halign: "left",
      valign: "top",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 10,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto",
    },
    columnStyles: columnStyles,
    willDrawCell: (data: any) => {
      if (data.row.index < 0) {
        return;
      }

      if (
        data.column.dataKey === "unitValue" &&
        (data.row.section === "head" || data.row.section === "body")
      ) {
        data.row.cells["unitValue"].styles = {
          ...data.row.cells["unitValue"].styles,
          halign: "center",
        };
      }

      if (
        data.column.dataKey === "qty" &&
        (data.row.section === "head" || data.row.section === "body")
      ) {
        data.row.cells["qty"].styles = {
          ...data.row.cells["qty"].styles,
          halign: "center",
        };
      }

      if (
        data.column.dataKey === "totalValue" &&
        (data.row.section === "head" || data.row.section === "body")
      ) {
        data.row.cells["totalValue"].styles = {
          ...data.row.cells["totalValue"].styles,
          halign: "center",
        };
      }

      if (
        data.column.dataKey === "weight" &&
        (data.row.section === "head" || data.row.section === "body")
      ) {
        data.row.cells["weight"].styles = {
          ...data.row.cells["weight"].styles,
          halign: "center",
        };
      }

      lastY = data.cursor.y;
      lastHeight = data.row.height;
      if (data.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        data.cell.contentHeight = 100;
        data.row.height = 100;
      }
    },
  });

  lastY = lastY + lastHeight + 10;
  lastY = lastY > 198 ? 198 : lastY;

  if (dataLength >= 3 && dataLength <= 5) {
    doc.addPage("a4", "l");
    lastY = 10;
  }
  // term ans sign table
  const columnsTerm = [
    {
      dataKey: "term",
    },
    {
      dataKey: "sign",
    },
  ];

  const columnTermStyle = {
    0: { cellWidth: 130 },
    1: { cellWidth: 60 },
  };

  doc.autoTable({
    body: [
      {
        term: translate("invoice.note"),
      },
      {
        term: translate("invoice.term"),
      },
      {
        sign: `${startCase(
          translate("invoice.signatureOfShipper")
        )}\n\n\n\n\n\n${headerData.sttSenderName}`,
      },
    ],
    columns: columnsTerm,
    theme: "plain",
    startY: lastY - 5,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 8,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0, right: 0, bottom: 0, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto",
    },
    columnStyles: columnTermStyle,
    willDrawCell: (data: any) => {
      if (data.row.index < 0) {
        return;
      }

      if (data.column.dataKey === "sign" || data.row.section === "body") {
        data.row.cells["sign"].styles = {
          ...data.row.cells["sign"].styles,
          fontStyle: "bold",
          halign: "center",
        };
      }
    },
  });

  doc.setProperties({
    title: "Commercial Invoice",
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default generateCommercialInvoice;
